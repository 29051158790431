.container {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: center;
}

.container-item {
  flex: 1;
  text-align: left;
}

#bg-image {
  background-image: url('./assets/cat-tracks-color.jpg');
  background-color: #cccccc;
  height: 100vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column;
  }
  .container-item {
    flex: none;
    width: 100%;
    text-align: center;
  }
}
