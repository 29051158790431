.container-c {
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: center;
}

.container-item-c {
  flex: 1;
  text-align: left;
}

.contact-text {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 800px;
  align-content: center;
  flex-direction: column;
}

#image-c {
  background-image: url('./assets/violin_bw.jpg');
  background-color: #cccccc;
  height: 100vh;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1000px) {
  .container-c {
    flex-direction: column;
    height: 100vh;
  }

  .container-item-c {
    flex: none;
    width: 100%;
    text-align: center;
  }

  .contact-text {
    display: flex;
    flex: none;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }

  #image-c {
    background-image: url('./assets/violin_bw.jpg');
    background-color: #cccccc;
    height: 30vh;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
