.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #2a623d;
  /* color: #5d5d5d; */
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #2a623d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.title {
  color: #2a623d;
  /* color: #aaaaaa; */
  /* color: #5d5d5d; */
}

.sub-title {
  /* color: #2a623d; */
  color: #aaaaaa;
  /* color: #5d5d5d; */
}

/* .description {
   color: #2a623d; 
   color: #aaaaaa; 
   color: #5d5d5d; 
} */

.experience {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
}

.about-link {
  color: #5d5d5d;
}

.container-about {
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  margin: 0;
  height: 100%;
  align-items: top;
}

.container-item-about {
  flex: 1;
  text-align: left;
  padding: 20px 20px;
}

#image-about {
  background-image: url('../assets/vertical-tree.jpg');
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: text-top;
}

@media (max-width: 800px) {
  .container-about {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    height: 100%;
    align-items: top;
  }
  #image-about {
    background-image: url('../assets/horizontal-fins.jpg');
    background-color: #cccccc;
    height: 30vh;
    background-position: center -25px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
